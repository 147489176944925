import React from 'react'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ProgramsAvailable.scss"

function ProgramsAvailable({ data }) {
  const settings = {
    dots: data.length > 1,
    infinite: data.length > 1,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="programs-slider-container">
      <Slider {...settings}>
        {data.map((item, index) => <div key={index}>
          { item?.redirect_url ? <img src={item?.media_url} alt={item.title} style={{ height: '500px', margin: 'auto', objectFit: 'cover !important', width: '100%', cursor: 'pointer' }} onClick={() => { window.open(`${item?.redirect_url}`, '_blank', 'noopener') }} />
            : <img src={item?.media_url} alt={item.title} style={{ height: '500px', margin: 'auto', objectFit: 'cover !important', width: '100%' }} />}
        </div>)}
      </Slider>
    </div>
  );
}

export default ProgramsAvailable
