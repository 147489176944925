import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import {
    Navbar,
    Container,
    Nav,
    NavDropdown,
    Form,
    Button,
} from 'react-bootstrap';
import RenderOffCanvas from '../offcanvas/offcanvas';
import './navbar.css';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { AzureLogout } from '../../containers/auth/AzureLogin';
import brandLogo from '../../assets/logo/AgilisiumFullWhiteLogo.png';
import PeopleHubLogo from '../../assets/logo/PeopleHubWhiteLogo.png';
import { NavLink } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';
import { MdHelpCenter } from 'react-icons/md';
import { FaHandsHelping } from 'react-icons/fa';
import { CgClose } from 'react-icons/cg';
import { getAuthProvider } from '../../containers/auth/azureAuthProvider';
import { StringEncryption } from '../../utils';
import axios from 'axios';

function Navigationbar() {
    const [show, setShow] = useState(false);
    const [showHelp, setShowHelp] = useState(false);
    const [helpText, setHelpText] = useState('');
    const [activeDropdown, setActiveDropdown] = useState(null);
    const navigate = useNavigate();
    const helpRef = useRef(null);
    const btnRef = useRef(null);
    const authState = useSelector((state) => state.authUser);
    const role_id = authState?.profile?.role?.role_id;

    const sendMail = async (content) => {
        const accessToken = await (
            await getAuthProvider().getAccessToken()
        ).accessToken;
        const encryptToken = StringEncryption(accessToken);
        const data = {
            from: `${authState?.profile?.email}`,
            to: 'hr@agilisium.com',
            subject: 'Help center',
            desc: content,
            accesToken: encryptToken,
        };
        const url = `${process.env.REACT_APP_API_DOMAIN}/api/helpcenter/sent-email`;
        const response = await axios.post(url, data);
        if (response) {
            setHelpText('');
            setShowHelp(false);
        }
    };

    const handleClose = (dropdown) => {
        setShow(false);
        setActiveDropdown(dropdown);
    };

    const handleClickOutside = (event) => {
        if (helpRef.current && !helpRef.current.contains(event.target) && btnRef.current && !btnRef.current.contains(event.target)) {
            setShowHelp(false);
        }
    };

      useEffect(() => {
        document.addEventListener('mouseout', handleClickOutside);
        return () => {
          document.removeEventListener('mouseout', handleClickOutside);
        };
      }, []);
        

    return (
        <>
            <Navbar
                expand="lg"
                className="bg-body-tertiary p-0"
                expanded={show}
                style={{position: 'fixed', width:'100%', zIndex:'999', padding:'100px !important'}}
            >
                <Container
                    fluid
                    style={{
                        background: '#3B6FD0',
                    }}
                >
                    <Navbar.Brand
                        className="brand"
                        onClick={() => {navigate('/'); handleClose('home')}}
                    >
                        <img
                            src={PeopleHubLogo}
                            alt="brand"
                            style={{
                                top: '19px',
                                left: '48px',
                                height: '58px',
                                // background: 'white 0% 0% no-repeat padding-box',
                                opacity: '1',
                                cursor: 'pointer',
                            }}
                        />
                    </Navbar.Brand>

                    <Navbar.Toggle
                        aria-controls="navbarScroll"
                        onClick={() => setShow(!show)}
                    />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className="me-auto my-2 my-lg-0"
                            // style={{ maxHeight: '100px' }}
                            navbarScroll
                        >
                            <Nav.Link
                                as={NavLink}
                                to="/home"
                                className={`px-4 navHeader ${activeDropdown === 'home' ? 'active' : ''}`}
                                onClick={() => handleClose('home')}
                            >
                                Home
                            </Nav.Link>
                            <NavDropdown
                                title="Our Hubs"
                                id="navbarScrollingDropdown"
                                className={`px-4 navHeader ${activeDropdown === 'ourhubs' ? 'active' : ''}`}
                            >
                                <NavDropdown.Item
                                    as={NavLink}
                                    to="/processHub"
                                    className="bg-transparent menulink"
                                    onClick={() => handleClose('ourhubs')}
                                >
                                    Process Hub
                                </NavDropdown.Item>
                                <NavDropdown.Divider className='divider'/>
                                <NavDropdown.Item
                                    as={NavLink}
                                    to="/talentTransformationalHub"
                                    className="bg-transparent menulink"
                                    onClick={() => handleClose('ourhubs')}
                                >
                                    Talent Transformational Hub
                                </NavDropdown.Item>
                                <NavDropdown.Divider className='divider'/>
                                <NavDropdown.Item
                                    as={NavLink}
                                    to="/marketingHub"
                                    className="bg-transparent menulink"
                                    onClick={() => handleClose('ourhubs')}
                                >
                                    Marketing Hub
                                </NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown
                                title="Explore"
                                id="navbarScrollingDropdown"
                                className={`px-4 navHeader ${activeDropdown === 'explore' ? 'active' : ''}`}
                            >
                                <NavDropdown.Item
                                    as={NavLink}
                                    to="/employeeGram"
                                    className="bg-transparent menulink"
                                    onClick={() => handleClose('explore')}
                                >
                                    Employee Gram
                                </NavDropdown.Item>
                                <NavDropdown.Divider className='divider'/>
                                <NavDropdown.Item
                                    as={NavLink}
                                    to="/findYourAssociates"
                                    className="bg-transparent menulink"
                                    onClick={() => handleClose('explore')}
                                >
                                    Find Your Associates
                                </NavDropdown.Item>
                                <NavDropdown.Divider className='divider'/>
                                <NavDropdown.Item
                                    as={NavLink}
                                    to="/policiesAndBenefits"
                                    className="bg-transparent menulink"
                                    onClick={() => handleClose('explore')}
                                >
                                    Policies and Benefits
                                </NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown
                                title="About"
                                id="navbarScrollingDropdown"
                                className={`px-4 navHeader ${activeDropdown === 'about' ? 'active' : ''}`}
                            >
                                <NavDropdown.Item
                                    href="https://www.agilisium.com/about-agilisium"
                                    target="_blank"
                                    className="bg-transparent menulink"
                                >
                                    Company
                                </NavDropdown.Item>
                                <NavDropdown.Divider className='divider'/>
                                <NavDropdown.Item
                                    as={NavLink}
                                    to="/departments"
                                    className="bg-transparent menulink"
                                    onClick={() => handleClose('about')}
                                >
                                    Departments
                                </NavDropdown.Item>
                                <NavDropdown.Divider className='divider'/>
                                <NavDropdown.Item
                                    as={NavLink}
                                    to="/organizationChart"
                                    className="bg-transparent menulink"
                                    onClick={() => handleClose('about')}
                                >
                                    Organization Chart
                                </NavDropdown.Item>
                                <NavDropdown.Divider className='divider'/>
                                <NavDropdown.Item
                                    as={NavLink}
                                    to="/clientSummary"
                                    className="bg-transparent menulink"
                                    onClick={() => handleClose('about')}
                                >
                                    Clients Summary
                                </NavDropdown.Item>
                            </NavDropdown>
                            {(role_id === 6 || role_id === 1) && <NavDropdown
                                title="Admin"
                                id="navbarScrollingDropdown"
                                className={`px-4 navHeader ${activeDropdown === 'admin' ? 'active' : ''}`}
                            >
                                {role_id === 6 && <NavDropdown.Item
                                    as={NavLink}
                                    to="/users"
                                    className="bg-transparent menulink"
                                    onClick={() => handleClose('admin')}
                                >
                                    Manage Users
                                </NavDropdown.Item>}
                                <NavDropdown.Divider className='divider'/>
                                <NavDropdown.Item
                                    as={NavLink}
                                    to="/walloffameDetails"
                                    className="bg-transparent menulink"
                                    onClick={() => handleClose('admin')}
                                >
                                    Manage Wall of Fame
                                </NavDropdown.Item>
                                <NavDropdown.Divider className='divider'/>
                                <NavDropdown.Item
                                    as={NavLink}
                                    to="/buddyreferrals"
                                    className="bg-transparent menulink"
                                    onClick={() => handleClose('admin')}
                                >
                                    Manage Buddy Referrals
                                </NavDropdown.Item>
                                <NavDropdown.Divider className='divider'/>
                                <NavDropdown.Item
                                    as={NavLink}
                                    to="/ManagePolicy"
                                    className="bg-transparent menulink"
                                    onClick={() => handleClose('admin')}
                                >
                                    Manage Policies and Benefits
                                </NavDropdown.Item>
                                <NavDropdown.Divider className='divider'/>
                                <NavDropdown.Item
                                    as={NavLink}
                                    to="/manageinsights"
                                    className="bg-transparent menulink"
                                    onClick={() => handleClose('admin')}
                                >
                                    Manage Agilisium Insights
                                </NavDropdown.Item>
                                <NavDropdown.Divider className='divider'/>
                                <NavDropdown.Item
                                    as={NavLink}
                                    to="/manageithub"
                                    className="bg-transparent menulink"
                                    onClick={() => handleClose('admin')}
                                >
                                    Manage IT Hub
                                </NavDropdown.Item>
                                <NavDropdown.Divider className='divider'/>
                                <NavDropdown.Item
                                    as={NavLink}
                                    to="/manageflashnews"
                                    className="bg-transparent menulink"
                                    onClick={() => handleClose('admin')}
                                >
                                    Manage Flash News
                                </NavDropdown.Item>
                                <NavDropdown.Divider className='divider'/>
                                <NavDropdown.Item
                                    as={NavLink}
                                    to="/managelearninglounge"
                                    className="bg-transparent menulink"
                                    onClick={() => handleClose('admin')}
                                >
                                    Manage Learning Lounge
                                </NavDropdown.Item>
                                <NavDropdown.Divider className='divider'/>
                                <NavDropdown.Item
                                    as={NavLink}
                                    to="/manageclientdetails"
                                    className="bg-transparent menulink"
                                    onClick={() => handleClose('admin')}
                                >
                                    Manage Client Details
                                </NavDropdown.Item>
                                <NavDropdown.Divider className='divider'/>
                                <NavDropdown.Item
                                    as={NavLink}
                                    to="/manageDepartments"
                                    className="bg-transparent menulink"
                                    onClick={() => handleClose('admin')}
                                >
                                    Manage Department Details
                                </NavDropdown.Item>
                                {/* <NavDropdown.Divider className='divider'/>
                                <NavDropdown.Item
                                    as={NavLink}
                                    to="/reports"
                                    className="bg-transparent menulink"
                                    onClick={() => handleClose('admin')}
                                >
                                    Reports
                                </NavDropdown.Item>
                                <NavDropdown.Divider className='divider'/> */}
                                {/* <NavDropdown.Item
                                    as={NavLink}
                                    to="/managesurvey"
                                    className="bg-transparent menulink"
                                    onClick={() => handleClose('admin')}
                                >
                                    Manage Survey
                                </NavDropdown.Item> */}
                            </NavDropdown>}
                        </Nav>
                    </Navbar.Collapse>
                    <Navbar.Text
                        className="helpCenter brand px-3"
                        onClick={() => {
                            setShowHelp(true);
                        }}
                        ref={btnRef}
                        style={{
                            cursor: 'pointer',
                            padding: '2px',
                            marginRight: '50px',
                            color: '#fff',
                            border: '1px solid',
                            borderRadius: '10px',
                        }}
                    >
                        Help Center
                        <FaHandsHelping size={30} />
                    </Navbar.Text>
                    {showHelp && (
                        <div id="popoverContent" className="popover-content" ref={helpRef}>
                            <div className="d-flex flex-column">
                                <p>
                                    Write your troubleshooting questions to our expertise.
                                </p>
                                <textarea
                                    className='mailDescTextarea'
                                    rows="5"
                                    cols="50"
                                    maxLength="200"
                                    value={helpText}
                                    onChange={(e) =>
                                        setHelpText(e.target.value)
                                    }
                                ></textarea>
                                <div className="d-flex justify-content-end">
                                    <Button
                                        variant="btn btn-secondary"
                                        className="my-2 me-1"
                                        style={{ width: '100px' }}
                                        onClick={() => {
                                            setHelpText('');
                                            setShowHelp(false);
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        variant="primary"
                                        className="my-2 ms-1"
                                        style={{ width: '100px' }}
                                        disabled={helpText === ''}
                                        onClick={() => sendMail(helpText)}
                                    >
                                        Send
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}

                    <Navbar.Brand
                        className="brand"
                        onClick={() => navigate('/')}
                    >
                        <img
                            src={brandLogo}
                            alt="brand"
                            style={{
                                top: '19px',
                                left: '48px',
                                width: '189px',
                                height: '58px',
                                // background: 'white 0% 0% no-repeat padding-box',
                                opacity: '1',
                                cursor: 'pointer',
                            }}
                        />
                    </Navbar.Brand>
                </Container>
            </Navbar>
        </>
    );
}

export default Navigationbar;
