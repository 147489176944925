import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import ContainerLoader from '../../components/loader/container-loader';
import AzureLogin from '../auth/AzureLogin';
import AuthenticateRoute from './AuthenticateRoute';
import RedirectIfAuthenticatedRoute from './RedirectIfAuthenticatedRoute';
import { MarketingHubRouteKeys, ProcessHubRouteKeys, RouteKeys } from './route-keys';
import AddUser from '../../components/add-user/addUser';
import WallOfFame from '../../components/wallOfFrame/walloffame';
import WhatsNew from '../../components/whatsnew/whatsnew';
import WhatsNewForm from '../../components/whatsnew/whatsNewForm';
import AddPolicyForm from '../../components/AddPolicyForm/AddPolicyForm';
import ManagePolicy from '../../components/AddPolicyForm/ManagePolicy';
import ClientSummary from '../../components/clientSummary/ClientSummary';
import Contact from '../../components/contact/Contact';
import WalloffameForm from '../../components/wallOfFrame/walloffameForm';
import ManageAgilisiumInsights from '../../components/manageAgilisiumInsights/ManageAgilisiumInsights';
import InsightsForm from '../../components/manageAgilisiumInsights/insightsForm';
import PrivacyPolicy from '../../components/privacyPolicy/PrivacyPolicy';
import TermsAndConditions from '../../components/termsandConditions/TermsAndConditions';
import ItHub from '../../components/It_hub/ItHub';
import ItHubForm from '../../components/It_hub/ItHubForm';
import FlashNews from '../../components/FlashNews/FlashNews';
import FlashNewsForm from '../../components/FlashNews/FlashNewsForm';
import OrganizationChart from '../about/organizationChart/OrganizationChart';
import ManageLearningLounge from '../../components/learningLoungeAdmin/ManageLearningLounge';
import LearningLoungeTable from '../../components/learningLoungeAdmin/LearningLoungeTable';
import LearningLoungeForm from '../../components/learningLoungeAdmin/LearningLoungeForm';
import LearningLounge from '../../components/learningLounge/LearningLounge';
import ClientDetails from '../../components/ClientDetails/ClientDetails';
import ClientDetailsForm from '../../components/ClientDetails/ClientDetailsForm';
import ManageDepartments from '../../components/managedeparments/ManageDepartments';
import DepartmentsForm from '../../components/managedeparments/DepartmentsForm';
import SimpleLineChart from '../../components/reportsPage/SimpleLineChart';
import Survey from '../../components/survey/Survey';
import ManageSurvey from '../../components/survey/ManageSurvey';
import ManageSurveyForm from '../../components/survey/SurveyMasterForm';
import SurveyMasterForm from '../../components/survey/SurveyMasterForm';
import SurveyQuestionsForm from '../../components/survey/SurveyQuestionsForm';
import ProcessHub from '../../components/processHub/ProcessHub';


const Home = lazy(() => import('../home'));
const EmployeeComms = lazy(() => import('../employeeComms'));
const NotFound = lazy(() => import('../notfound'));
const AuthPage = lazy(() => import('../auth'));
const CreateUser = lazy(() => import('../../components/Crud/CreateUser'));
const EditUser = lazy(() => import('../../components/Crud/EditUser'));
const About = lazy(() => import('../about'))
const KnowYourAssociates = lazy(() => import('../../components/knowYourAssociates/KnowYourAssociates'))
const AssociateDetails = lazy(() => import('../../components/associateDetails'))
const Policies = lazy(() => import('../../components/policies/Policies'))
const ComingSoon = lazy(() => import('../../components/comingsoon/ComingSoon'))
const ManageDocuments = lazy(() => import('../../components/manageDocuments/ManageDocuments'))
const DocumentsForm = lazy(() => import('../../components/manageDocuments/DocumentsForm'))
const MarketingHub = lazy(() => import('../../components/marketingHub/MarketingHub'))
const Solutions = lazy(() => import('../../components/marketingHub/solutions/Solutions'))
const ManageSolutions = lazy(() => import('../../components/marketingHub/manageSolutions/ManageSolutions'))
const SolutionsForm = lazy(() => import('../../components/marketingHub/manageSolutions/SolutionsForm'))
const ManageMarketingBrochures = lazy(() => import('../../components/marketingHub/manageMarketingBrochures/ManageMarketingBrochures'))
const MarketingBrochuresForm = lazy(() => import('../../components/marketingHub/manageMarketingBrochures/MarketingBrochuresForm'))
const ManageMarketingEvents = lazy(() => import('../../components/marketingHub/manageMarketingEvents/ManageMarketingEvents'))
const MarketingEventsForm = lazy(() => import('../../components/marketingHub/manageMarketingEvents/MarketingEventsForm'))
const ManageRecommendedVideos = lazy(() => import('../../components/marketingHub/manageRecommendedVideos/ManageRecommendedVideos'))
const RecommendedVideosForm = lazy(() => import('../../components/marketingHub/manageRecommendedVideos/RecommendedVideosFrom'))
const ManageMarketingVideos = lazy(() => import('../../components/marketingHub/manageMarketingVideos/ManageMarketingVideos'))
const MarketingVideosForm = lazy(() => import('../../components/marketingHub/manageMarketingVideos/MarketingVideosForm'))
const ManageIndustryNews = lazy(() => import('../../components/marketingHub/manageindustrynews/ManageIndustryNews'))
const IndustryNewsForm = lazy(() => import('../../components/marketingHub/manageindustrynews/IndustryNewsForm'))
const ManageThoughtLeadership = lazy(() => import('../../components/marketingHub/managethoughtleadership/ManageThoughtLeadership'))
const ThoughtLeadershipForm = lazy(() => import('../../components/marketingHub/managethoughtleadership/ThoughtLeadershipForm'))
const ManagePodcasts = lazy(() => import('../../components/marketingHub/managemarketingpodcasts/ManagePodcasts'))
const PodcastsForm = lazy(() => import('../../components/marketingHub/managemarketingpodcasts/PodcastsForm'))
const ManageMarketingAwards = lazy(() => import('../../components/marketingHub/managemarketingawards/ManageMarketingAwards'))
const MarketingAwardsForm = lazy(() => import('../../components/marketingHub/managemarketingawards/MarketingAwardsForm'))
const ManageAuthors = lazy(() => import('../../components/marketingHub/manageSolutionAuthors/ManageAuthors'))
const AuthorsForm = lazy(() => import('../../components/marketingHub/manageSolutionAuthors/AuthorsForm'))






class NavRoutes extends React.Component {
    render() {
        return (
            <Suspense fallback={<ContainerLoader />}>
                <Routes>
                    <>
                        <Route
                            path={RouteKeys.Home}
                            element={
                                <AuthenticateRoute>
                                    <Home />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.Homeroute}
                            element={
                                <AuthenticateRoute>
                                    <Home />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.AddUser}
                            element={
                                <AuthenticateRoute>
                                    <AddUser />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.Auth}
                            element={
                                <RedirectIfAuthenticatedRoute>
                                    <AuthPage />
                                </RedirectIfAuthenticatedRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.AzureLogin}
                            element={
                                <RedirectIfAuthenticatedRoute>
                                    <AzureLogin />
                                </RedirectIfAuthenticatedRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.CreateUser}
                            element={
                                <AuthenticateRoute>
                                    <CreateUser />
                                </AuthenticateRoute>
                            }
                        />

                        <Route
                            path={RouteKeys.EditUser}
                            element={
                                <AuthenticateRoute>
                                    <EditUser />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.EmployeeGram}
                            element={
                                <AuthenticateRoute>
                                    <EmployeeComms />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.About}
                            element={
                                <AuthenticateRoute>
                                    <About />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.FindYourAssociates}
                            element={
                                <AuthenticateRoute>
                                    <KnowYourAssociates />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.AssociateDetails}
                            element={
                                <AuthenticateRoute>
                                    <AssociateDetails />
                                </AuthenticateRoute>
                            }
                        />
                        <Route path={RouteKeys.wallOfFame} element={<AuthenticateRoute>
                            <WallOfFame />
                        </AuthenticateRoute>}>

                        </Route>

                        <Route path={RouteKeys.whatsnew} element={<AuthenticateRoute>
                            <WhatsNew />
                        </AuthenticateRoute>}>

                        </Route>

                        <Route
                            path={RouteKeys.PoliciesAndBenefits}
                            element={
                                <AuthenticateRoute>
                                    <Policies />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.AddPolicyForm}
                            element={
                                <AuthenticateRoute>
                                    <AddPolicyForm />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.ManagePolicy}
                            element={
                                <AuthenticateRoute>
                                    <ManagePolicy />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.ClientSummary}
                            element={
                                <AuthenticateRoute>
                                    <ClientSummary />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.WhatsNewForm}
                            element={
                                <AuthenticateRoute>
                                    <WhatsNewForm />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.Departments}
                            element={
                                <AuthenticateRoute>
                                    <Contact />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.LearningLounge}
                            element={
                                <AuthenticateRoute>
                                    <LearningLounge />
                                </AuthenticateRoute>
                            }
                        />

                        <Route path={RouteKeys.wallofFameForm} element={<AuthenticateRoute>
                            <WalloffameForm />
                        </AuthenticateRoute>}>

                        </Route>
                        <Route
                            path={RouteKeys.ManageInsights}
                            element={
                                <AuthenticateRoute>
                                    <ManageAgilisiumInsights />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.InsightsForm}
                            element={
                                <AuthenticateRoute>
                                    <InsightsForm />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.ManageItHub}
                            element={
                                <AuthenticateRoute>
                                    <ItHub />
                                </AuthenticateRoute>
                            }
                        />
                        <Route path={RouteKeys.ItHubForm} element={<AuthenticateRoute>
                            <ItHubForm />
                        </AuthenticateRoute>}>

                        </Route>
                        <Route
                            path={RouteKeys.ManageFlashNews}
                            element={
                                <AuthenticateRoute>
                                    <FlashNews />
                                </AuthenticateRoute>
                            }
                        />
                        <Route path={RouteKeys.FlashNewsForm} element={<AuthenticateRoute>
                            <FlashNewsForm />
                        </AuthenticateRoute>}>

                        </Route>
                        <Route
                            path={RouteKeys.PrivacyPolicy}
                            element={
                                <AuthenticateRoute>
                                    <PrivacyPolicy />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.TermsAndConditions}
                            element={
                                <AuthenticateRoute>
                                    <TermsAndConditions />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.OrganizationChart}
                            element={
                                <AuthenticateRoute>
                                    <OrganizationChart />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.MangageLearningLounge}
                            element={
                                <AuthenticateRoute>
                                    <ManageLearningLounge />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.LearningLoungeTable}
                            element={
                                <AuthenticateRoute>
                                    <LearningLoungeTable />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.LearningLoungeForm}
                            element={
                                <AuthenticateRoute>
                                    <LearningLoungeForm />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.ManageClientDetails}
                            element={
                                <AuthenticateRoute>
                                    <ClientDetails />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.ClientDetailsForm}
                            element={
                                <AuthenticateRoute>
                                    <ClientDetailsForm />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.ManageDepartments}
                            element={
                                <AuthenticateRoute>
                                    <ManageDepartments />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={ProcessHubRouteKeys.ManageDocuments}
                            element={
                                <AuthenticateRoute>
                                    <ManageDocuments />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={ProcessHubRouteKeys.DocumentsForm}
                            element={
                                <AuthenticateRoute>
                                    <DocumentsForm />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.DepartmentsForm}
                            element={
                                <AuthenticateRoute>
                                    <DepartmentsForm />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.Reports}
                            element={
                                <AuthenticateRoute>
                                    <SimpleLineChart />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.Survey}
                            element={
                                <AuthenticateRoute>
                                    <Survey />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.ManageSurvey}
                            element={
                                <AuthenticateRoute>
                                    <ManageSurvey />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.SurveyMasterForm}
                            element={
                                <AuthenticateRoute>
                                    <SurveyMasterForm />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.SurveyQuestionsForm}
                            element={
                                <AuthenticateRoute>
                                    <SurveyQuestionsForm />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={ProcessHubRouteKeys.ProcessHub}
                            element={
                                <AuthenticateRoute>
                                    <ProcessHub />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.TalentTransformationalHub}
                            element={
                                <AuthenticateRoute>
                                    <LearningLounge />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={MarketingHubRouteKeys.MarketingHub}
                            element={
                                <AuthenticateRoute>
                                    <MarketingHub />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={MarketingHubRouteKeys.ManageMarketingBrochures}
                            element={
                                <AuthenticateRoute>
                                    <ManageMarketingBrochures />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={MarketingHubRouteKeys.MarketingBrochuresForm}
                            element={
                                <AuthenticateRoute>
                                    <MarketingBrochuresForm />
                                </AuthenticateRoute>
                                }
                            />
                        <Route
                            path={MarketingHubRouteKeys.ManageMarketingEvents}
                            element={
                                <AuthenticateRoute>
                                    <ManageMarketingEvents />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={MarketingHubRouteKeys.MarketingEventsForm}
                            element={
                                <AuthenticateRoute>
                                    <MarketingEventsForm />
                                </AuthenticateRoute>
                                }
                            />
                        <Route
                            path={MarketingHubRouteKeys.Solutions}
                            element={
                                <AuthenticateRoute>
                                    <Solutions />
                                </AuthenticateRoute>
                            }
                        />
                         <Route
                            path={MarketingHubRouteKeys.ManageSolutions}
                            element={
                                <AuthenticateRoute>
                                    <ManageSolutions />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={MarketingHubRouteKeys.ManageIndustryNews}
                            element={
                                <AuthenticateRoute>
                                    <ManageIndustryNews />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={MarketingHubRouteKeys.SolutionsForm}
                            element={
                                <AuthenticateRoute>
                                    <SolutionsForm />
                                    </AuthenticateRoute>
                            }
                        />
                        <Route
                                path={MarketingHubRouteKeys.IndustryNewsForm}
                            element={
                                <AuthenticateRoute>
                                    <IndustryNewsForm />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={MarketingHubRouteKeys.ManageRecommendedVideos}
                            element={
                                <AuthenticateRoute>
                                    <ManageRecommendedVideos />
                                    </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={MarketingHubRouteKeys.ManageThoughtLeadership}
                            element={
                                <AuthenticateRoute>
                                    <ManageThoughtLeadership />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={MarketingHubRouteKeys.RecommendedVideosForm}
                            element={
                                <AuthenticateRoute>
                                    <RecommendedVideosForm />
                                    </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={MarketingHubRouteKeys.ThoughtLeadershipForm}
                            element={
                                <AuthenticateRoute>
                                    <ThoughtLeadershipForm />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={MarketingHubRouteKeys.ManageMarketingVideos}
                            element={
                                <AuthenticateRoute>
                                    <ManageMarketingVideos />
                                    </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={MarketingHubRouteKeys.ManagePodcasts}
                            element={
                                <AuthenticateRoute>
                                    <ManagePodcasts />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={MarketingHubRouteKeys.MarketingVideosForm}
                            element={
                                <AuthenticateRoute>
                                    <MarketingVideosForm />
                                    </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={MarketingHubRouteKeys.PodcastsForm}
                            element={
                                <AuthenticateRoute>
                                    <PodcastsForm />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={MarketingHubRouteKeys.ManageMarketingAwards}
                            element={
                                <AuthenticateRoute>
                                    <ManageMarketingAwards />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={MarketingHubRouteKeys.MarketingAwardsForm}
                            element={
                                <AuthenticateRoute>
                                    <MarketingAwardsForm />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={MarketingHubRouteKeys.ManageAuthors}
                            element={
                                <AuthenticateRoute>
                                    <ManageAuthors />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={MarketingHubRouteKeys.AuthorsForm}
                            element={
                                <AuthenticateRoute>
                                    <AuthorsForm />
                                </AuthenticateRoute>
                            }
                        />
                        <Route path={RouteKeys.NotFound} element={NotFound} />
                        <Route path="*" element={NotFound} />
                    </>
                </Routes>
            </Suspense>
        );
    }
}

export default NavRoutes;
