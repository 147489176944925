import { BiSolidFilePdf, BiSolidFileDoc, BiSolidFileHtml } from "react-icons/bi";
import { FaFileExcel } from "react-icons/fa6";
import { AiFillFilePpt } from "react-icons/ai";
import { RiFileTextFill } from "react-icons/ri";
import { FaFile } from "react-icons/fa";

export function getDocumentTypeFromUrl(url) {
  const parts = url.split('/');
  const lastPart = parts[parts.length - 1];
  const fileParts = lastPart.split('.');
  if (fileParts.length > 1) {
    return fileParts[fileParts.length - 1];
  } else {
    return null;
  }
}

export function getIconFromUrl(url) {
  const docType = getDocumentTypeFromUrl(url)
  if (docType === 'pdf') {
    return <BiSolidFilePdf fill='#e41e23' size={24} className="me-2" />
  } else if (docType === 'doc' || docType === 'docx') {
    return <BiSolidFileDoc fill='#0163d1' size={24} className="me-2" />
  } else if (docType === 'xls' || docType === 'xlsx') {
    return <FaFileExcel fill='#1d6f42' size={22} className="me-2" />
  } else if (docType === 'ppt' || docType === 'pptx') {
    return <AiFillFilePpt fill='#D04423' size={24} className="me-2" />
  } else if (docType === 'html') {
    return <BiSolidFileHtml fill='#f06529' size={24} className="me-2" />
  } else if (docType === 'txt' || docType === 'rtf') {
    return <RiFileTextFill fill='#6404c4' size={24} className="me-2" />
  } else {
    return <FaFile fill='#fccc77' size={22} className="me-2" />
  }
}

