import React from 'react'
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { toastr } from 'react-redux-toastr';
import api from '../../api';
import 'bootstrap/dist/css/bootstrap.min.css';
import ContainerLoader from '../loader/container-loader';
import DataTables from '../DataTables/DataTable';
import styles from '../wallOfFrame/wallOfFrame.module.css'
import { GrEdit } from "react-icons/gr";
import { MdDelete } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import { useSelector } from 'react-redux';

const ManageDepartments = () => {

    const [data, setData] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    const [currentId, setCurrentId] = useState(null);
    const navigate = useNavigate();
    const authState = useSelector((state) => state.authUser);
    const role_id = authState?.profile?.role?.role_id;

    const groupedData = (baseData) => {
        const finaldata = baseData.map(parent => ({
                ...parent,
                subDepartments: baseData.filter(item => item.parentid === parent.id)
                    .sort((a, b) => b.department.localeCompare(b.department)) // Add subDepartments to each Department
            })).sort((a, b) => a.department.localeCompare(b.department)); // sort subDepartments
        return finaldata;
    } // sort by department name

    const fetchDepartments = async () => {
        try {
            setIsFetching(true);
            const request = { resource: 'api/departments' };
            const response = await api.clientsinformation.getDetails(request);
            const filteredData = response.data.data.res.filter(item => (item.isdeleted === false));
            const finaldata = groupedData(filteredData);
            setData(finaldata);
        } catch (error) {
            console.log(error.message);
        } finally {
            setIsFetching(false);
        }
    }

    const getDeptById = (data, id) => {
            const parentDept = data?.filter(item => item?.id === id);
            return parentDept[0]?.department || '-';
    }

    useEffect(() => {
        fetchDepartments();
    }, []);

    const columns = [
        {
            dataField: 'department',
            text: 'Department',
            sort: true,
        },
        {
            dataField: 'email',
            text: 'Email',
            sort: true,
        },
        {
            dataField: 'about',
            text: 'About',
            sort: true,
            formatter: (cellContent, row) => {
                return (<div className={`${styles.ellipsisColumn}`} title={cellContent}>{cellContent}</div>);
            }    
        },
        {
            dataField: 'spoc',
            text: 'SPOC',
            sort: true,
        },
        {
            dataField: 'spocemail',
            text: 'SPOC Email',
            sort: true,
        },
        {
            dataField: 'issubdepartment',
            text: 'Is Sub Dept.',
            sort: true,
            formatter: (cellContent, row) => {
                return <input type = "checkbox" defaultChecked={row?.issubdepartment === true}/>
                ;
            }
        },
        {
            dataField: 'parent_dept',
            text: 'Parent Dept.',
            sort: true,
            formatter: (cellContent, row) => getDeptById(data, row?.parentid),
            sortValue: (cellContent, row) => getDeptById(data, row?.parentid)
        },
        {
            dataField: 'action',
            text: 'Actions',
            formatter: (cell, row) => (
                <>
                    <GrEdit size={20} title={'Edit'} className='me-1' onClick={() => handleEdit(row)} />
                    <MdDelete size={20} title={'Delete'} className='ms-1' onClick={() => handleDeleteClick(row)} />
                </>
            ),
            sort: false,
        }
    ];

    const handleDeleteClick = async (row) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this record?');
        if (!confirmDelete) return;
        try {
            setIsFetching(true)
            const request = { resource: `api/departments/${row.id}` };
            const response = await api.departments.deleteRecord(request); // Hard delete
            if (response.data.status === 200) {
                toastr.success('Record deleted successfully!');
                window.location.reload(); // Refresh the data after deletion
            } else {
                toastr.error('Failed to delete the record.');
            }
        } catch (error) {
            console.log(error.message)
        } finally {
            setIsFetching(false);
        }
    }

    const handleEdit = (row) => {
        const hasNullField = [
            row.department,
            row.spoc,
        ].some(field => field === null || field === '');

        // If any field is null, show error and return from the function
        if (hasNullField) {
            toastr.error('Missing Required Fields!');
            return;
        }
        setCurrentId(row.id);
        navigate('/departmentsForm', { state: { data, currentId: row.id, intialData: row, isEdit: true } })
    };

    const handleNew = () => {
        navigate('/departmentsForm', { state: { data, currentId, isEdit: false } })
    }

    useEffect(() => {
        if ((role_id !== 1) && (role_id !== 6)) {
            navigate('/home');
        }
    }, [])

    useEffect(() => {
        fetchDepartments();
    }, [])

    return (
        <div className={styles.fameContainer} style={{ minHeight: '1150px', height: 'auto', overflow: 'auto' }}>
            <div className={`${styles.dataTableWrapper} mx-auto d-flex justify-content-between p-2`}>
                <h4 className="my-4 managePageTitle">Manage Department Details</h4>
                {!isFetching && <div className={`${styles.filtersWrap} d-flex align-items-center  justify-content-end mt-3 mb-1`}>
                    <Button className={styles.addRecords} onClick={handleNew} variant="primary">
                        Add New
                    </Button>
                </div>}
            </div>
            {isFetching && <ContainerLoader />}
            <div className="walloffame_wrap mb-5">
                {(data && data?.length > 0 && !isFetching) && (<div className='row'>
                    <div className='col-12'>
                        <div className={`${styles.dataTableWrapper} mx-auto`}>
                            <DataTables records={data} columns={columns} />
                        </div>
                    </div>
                </div>)}

                {data?.length === 0 && (!isFetching) && <Alert variant='danger' className='mx-auto' style={{ width: '90%' }}>No Records Found!!!</Alert>}

            </div>
        </div>
    )
}

export default ManageDepartments
