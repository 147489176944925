import React, { useEffect, useState } from 'react';
import api from '../../../api';
import './SideBar.scss';
import ContainerLoader from '../../loader/container-loader';


function SideBar({ selectedDept, setSelectedDept }) {
    const [depts, setDepts] = useState([]);
    const [isFetching, setIsFetching] = useState(false);

    const fetchDepartments = async () => {
        try {
            setIsFetching(true);
            const request = { resource: 'api/departments' };
            const response = await api.clientsinformation.getDetails(request);
            const filteredData = response?.data?.data?.res?.filter(item => (item.isdeleted === false));
            const sortedData = filteredData?.sort((a, b) => a.department.toLowerCase() > b.department.toLowerCase() ? 1 : -1);
            setDepts(sortedData);
            setSelectedDept(sortedData[0]);
        } catch (error) {
            console.log(error.message);
        } finally {
            setIsFetching(false);
        }
    }

    useEffect(() => {
        fetchDepartments();
    }, [])

    return (
        <div className=''>
            {isFetching ? <ContainerLoader /> :
                <ul className="list-unstyled">
                    {depts?.map((item, index) => <li key={index} className={`text-white text-decoration-none dept ${selectedDept === item ? 'selectedDept' : ''}`} onClick={() => { setSelectedDept(item) }}>{item?.department}</li>)}
                </ul>
            }
        </div>
    )
}

export default SideBar