import React from 'react';

function About({ selectedDept }) {
    return (
        <div className="p-2   rounded" style={{ height: '136px' }}>
            <div className="d-flex flex-column">
                <h5 className="text-primary fs-6 text-bold mb-3">
                    About {selectedDept?.department}
                </h5>
                <div style={{ height: '90px', overflow: 'auto' }}>
                    <p className="text-muted mb-0" style={{ fontSize: '12px' }}>
                        {selectedDept?.about ||
                            'Information about this department is not available.'}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default About;
