export const formatDate = (value) => {
  return value ? new Date(value).toISOString().split('T')[0] : null
  }

  export const formatMMMDDYYYY = (value) => {
    if (!value) return null;
  
    const date = new Date(value);
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  };