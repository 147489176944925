import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { toastr } from 'react-redux-toastr';
import api from '../../api';
import { useForm } from 'react-hook-form';
import 'bootstrap/dist/css/bootstrap.min.css';
import ContainerLoader from '../loader/container-loader';
import styles from '../wallOfFrame/wallOfFrame.module.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './Department.scss';

const DepartmentsForm = () => {
    const [isfetching, setIsfetching] = useState(false);
    const [showForm, setShowForm] = useState(true);
    const [allUsers, setAllUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [inputValue, setInputValue] = useState('');

    const location = useLocation();
    const { state } = useLocation();
    const navigate = useNavigate();

    const intialData = location.state?.intialData || {};
    const currentId = location.state?.currentId || {};
    const isEdit = state ? state.isEdit : false;
    const authState = useSelector((state) => state.authUser);
    const role_id = authState?.profile?.role?.role_id;
    const [isSubDepartmentChecked, setIsSubDepartmentChecked] = useState(false);
    const parents = location.state?.data?.filter(
        (item) => item.id !== currentId && item.issubdepartment === false,
    );

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setError,
        clearErrors,
        setValue,
        control,
    } = useForm();

    useEffect(() => {
        const fetchAllUsers = async () => {
            try {
                setIsfetching(true);
                setShowForm(false);
                const response = await api.clientsinformation.getDetails({
                    resource: `api/zoho/getAllEmployees`,
                });
                const details = response?.data?.details || [];
                setAllUsers(details);
            } catch (error) {
                console.error('Error fetching user data:', error);
            } finally {
                setIsfetching(false);
                setShowForm(true);
            }
        };

        fetchAllUsers();
    }, []);


    const handleInputChange = (e) => {
        const value = e.target.value.trim();
        setInputValue(value); 
        setValue('spocemail', value); 

        if (!value) {
            setFilteredUsers([]);
            clearErrors('spocemail'); 
            return;
        }

        const filtered = allUsers.filter((user) =>
            user.emailid.toLowerCase().includes(value.toLowerCase()),
        );
        setFilteredUsers(filtered);

        if (filtered.length === 0) {
            setError('spocemail', {
                type: 'manual',
                message: 'No matching users found',
            });
        } else {
            clearErrors('spocemail');
        }
    };



    const handleSelect = (email) => {
        const user = allUsers.find((u) => u.emailid === email);
        setSelectedUser(user);
        setInputValue(email);
        setValue('spocemail', email);
        setValue('spoc', user.firstname + ' ' + user.lastname);
        setFilteredUsers([]);
        clearErrors('spocemail'); 

    };

    useEffect(() => {
        if (intialData) {
            Object.keys(intialData).forEach((key) => {
                if (key === 'issubdepartment') {
                    const isChecked = intialData.issubdepartment === true;
                    setValue('issubdepartment', isChecked);
                    setIsSubDepartmentChecked(isChecked);
                } else if (key === 'parentid') {
                    const parentid = intialData.parentid;
                    setValue('parentid', parentid);
                } else if (key === 'spoc'){
                    const temp = intialData.spoc.split(' ')
                    setSelectedUser( {firstname :temp[0], lastname:temp[1]},);
                    }

                else {
                    setValue(key, intialData[key]);
                }
            });
        }
    }, [intialData, setValue]);

    const handleCheckboxChange = (e) => {
        setIsSubDepartmentChecked(!isSubDepartmentChecked);
        setValue('parentid', null);
    };

    const handleData = async (formData) => {
        if (isEdit) {
            try {
                setIsfetching(true);
                setShowForm(false);
                const request = { resource: `api/departments/${currentId}` };
                const editResponse = await api.departments.updateDetails(
                    request,
                    {
                        ...formData,
                    },
                );

                if (editResponse.data.status === 200) {
                    toastr.success('Record updated successfully!');
                    navigate('/manageDepartments');
                }
                setIsfetching(false);
            } catch (error) {
                console.log(error.message);
            }
        } else {
            try {
                const request = { resource: 'api/departments' };
                setIsfetching(true);
                setShowForm(false);
                const res = await api.departments.addDetails(request, {
                    ...formData,
                });

                if (res.data.status === 200) {
                    toastr.success('Success', ' Record added Successfully!');
                    setIsfetching(false);
                    navigate('/manageDepartments');
                } else {
                    toastr.error('Error', res.data.message);
                }
            } catch (error) {
                console.log(error.message);
            }
        }
        reset();
        setIsfetching(false);
        setShowForm(true);
    };

    useEffect(() => {
        if (role_id !== 1 && role_id !== 6) {
            navigate('/home');
        }
    }, []);

    return (
        <div
            className={`${styles.dataTableWrapper} ${styles.formWrapper} mx-auto p-2`}
        >
            <h4 className="my-4 pagetitle">Departments Form</h4>
            {isfetching && <ContainerLoader />}
            {showForm && (
                <div className="row">
                    <div className="col-12">
                        <div className="walloffame_form w-sm-100 mx-auto">
                            <Form
                                onSubmit={handleSubmit(handleData)}
                                className="d-flex flex-wrap justify-content-between"
                            >
                                <div className="col-12 col-md-6 p-2">
                                    <Form.Group
                                        className="mb-3"
                                        controlId="exampleForm.ControlTextarea1"
                                    >
                                        <Form.Label>Department<span className='text-danger'>*</span></Form.Label>
                                        <Form.Control
                                            name="department"
                                            type="text"
                                            {...register('department', {
                                                required:
                                                    'Department is required',
                                            })}
                                        />
                                        {errors.department && (
                                            <p style={{ color: 'red' }}>
                                                {errors.department.message}
                                            </p>
                                        )}
                                    </Form.Group>
                                </div>
                                <div className="col-12 col-md-6 p-2">
                                    <Form.Group
                                        className="mb-3"
                                        controlId="exampleForm.ControlTextarea1"
                                    >
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            name="email"
                                            type="text"
                                            {...register('email')}
                                        />
                                        {errors.email && (
                                            <p style={{ color: 'red' }}>
                                                {errors.email.message}
                                            </p>
                                        )}
                                    </Form.Group>
                                </div>

                                <div className="col-12 col-md-6 p-2">
            <Form.Group className="mb-3 position-relative">
                <Form.Label>SPOC Email<span className='text-danger'>*</span></Form.Label>
                <Form.Control
                    name="spocemail"
                    type="text"
                    {...register('spocemail', {
                        required: 'Email is required',
                        pattern: {
                            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                            message: 'Invalid email format',
                        },
                    })}
                    placeholder="Enter Here"
                    autoComplete="off"
                    onChange={handleInputChange} 
                />
                {errors.spocemail && (
                    <p style={{ color: 'red' }}>{errors.spocemail.message}</p>
                )}
                {filteredUsers.length > 0 && (
                    <div className="dropdownSuggestions">
                        {filteredUsers.map((user) => (
                            <div
                                key={user.employeeid}
                                className="dropdown-item"
                                onClick={() => handleSelect(user.emailid)}
                            >
                                {user.firstname} {user.lastname} ({user.emailid})
                            </div>
                        ))}
                    </div>
                )}
            </Form.Group>
        </div>

                                <div className="col-12 col-md-6 p-2">
                                    <Form.Group
                                        className="mb-3"
                                        controlId="exampleForm.ControlTextarea1"
                                    >
                                        <Form.Label>Spoc</Form.Label>
                                        <Form.Control
                                        name='spoc'
                                            value={
                                                selectedUser
                                                    ? `${selectedUser.firstname} ${selectedUser.lastname}`
                                                    : ''
                                            }
                                        />
                                    </Form.Group>
                                </div>
                                <div className='col-12 col-md-12 p-2'>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="exampleForm.ControlTextarea1"
                                    >
                                        <Form.Label>About<span className='text-danger'>*</span></Form.Label>
                                        <Form.Control
                                            name="about"
                                            as="textarea"
                                            rows={3}
                                            {...register('about', { required: 'About is required' })}
                                        />
                                        {errors.about && <p style={{ color: 'red' }}>{errors.about.message}</p>}
                                    </Form.Group>
                                </div>
                                <div className="col-12 col-md-6 d-flex flex-row">
                                    <div className="col-12 col-md-6 p-2">
                                        <Form.Group>
                                            <Form.Label>
                                                Is Sub-Department
                                            </Form.Label>
                                            <Form.Check
                                                type="checkbox"
                                                label="Is Sub-Department"
                                                name="issubdepartment"
                                                {...register('issubdepartment')}
                                                checked={isSubDepartmentChecked}
                                                onChange={handleCheckboxChange}
                                            />
                                            {errors.issubdepartment && (
                                                <p style={{ color: 'red' }}>
                                                    {
                                                        errors.issubdepartment
                                                            .message
                                                    }
                                                </p>
                                            )}
                                        </Form.Group>
                                    </div>
                                    {isSubDepartmentChecked && (
                                        <div className="col-12 col-md-6 p-2">
                                            <Form.Group>
                                                <Form.Label>
                                                    Select Parent
                                                </Form.Label>
                                                <Form.Select
                                                    name="parentid"
                                                    {...register('parentid', {
                                                        required:
                                                            'Parent is required',
                                                    })}
                                                    className="mb-3"
                                                    aria-label="Default select example"
                                                    placeholder="Select Parent"
                                                >
                                                    <option value="">
                                                        Select Parent
                                                    </option>
                                                    {parents.map(
                                                        (item, index) => (
                                                            <option
                                                                key={index}
                                                                value={item.id}
                                                            >
                                                                {
                                                                    item.department
                                                                }
                                                            </option>
                                                        ),
                                                    )}
                                                </Form.Select>
                                                {errors.parentid && (
                                                    <p style={{ color: 'red' }}>
                                                        {
                                                            errors.parentid
                                                                .message
                                                        }
                                                    </p>
                                                )}
                                            </Form.Group>
                                        </div>
                                    )}
                                </div>

                                <div className="d-flex justify-content-end col-12 p-2">
                                    <Button
                                        variant="secondary"
                                        className="me-2"
                                        onClick={() =>
                                            navigate('/manageDepartments')
                                        }
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        type="reset"
                                        variant="secondary"
                                        className="mx-2"
                                        onClick={() => {
                                            reset();
                                            setIsSubDepartmentChecked(false);
                                            setSelectedUser(null);
                                        }}
                                    >
                                        Reset
                                    </Button>
                                    <Button
                                        type="submit"
                                        variant="primary"
                                        className="ms-2"
                                    >
                                        Save
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DepartmentsForm;
