import React,{useEffect,useState} from 'react'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { toastr } from 'react-redux-toastr';
import api from '../../api';
import { useForm,Controller } from 'react-hook-form';
import 'bootstrap/dist/css/bootstrap.min.css';
import ContainerLoader from '../loader/container-loader';
import styles from '../wallOfFrame/wallOfFrame.module.css'
import { useLocation, useNavigate } from 'react-router-dom';
import {MultiSelect} from 'react-multi-select-component';
import { useSelector } from 'react-redux';

const ItHubForm = () => {

    const [isfetching, setIsfetching] = useState(false);
    const [showForm, setShowForm] = useState(true)
    const location = useLocation();
    const { state } = useLocation();
    const navigate = useNavigate();

    const intialData = location.state?.intialData || {};
    const data = location.state?.data || {};
    const currentId = location.state?.currentId || {};
    const sites = location.state?.sites || {};
    const isEdit = state ? state.isEdit : false;

    const authState = useSelector((state) => state.authUser);
    const role_id = authState?.profile?.role?.role_id;

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        control
    } = useForm();

    useEffect(() => {
        if (intialData) {
            Object.keys(intialData).forEach((key) => {
                if (key === 'publishdate') {
                    const formattedPublishDate = intialData.publishdate ? new Date(intialData.publishdate).toISOString().split('T')[0] : null;
                    setValue('publishdate', formattedPublishDate);
                } else if (key === 'expirydate') {
                    const formattedExpiryDate = intialData.expirydate ? new Date(intialData.expirydate).toISOString().split('T')[0] : null;
                    setValue('expirydate', formattedExpiryDate);
                } else if (key === 'isactive' || key === 'isarchived' || key === 'isinactive') {
                    setValue('status_ithub', intialData.isactive ? '1' : intialData.isarchived ? '2' : intialData.isinactive ? '3' : '');
                } else {
                    setValue(key, intialData[key]);
                }
            });
        }
    }, [intialData, setValue]);


    const handleData = async (formData) => {
        if (isEdit) {
            try {
                setIsfetching(true);
                setShowForm(false)
                const request = { resource: `api/ithub/${currentId}` };
                const editResponse = await api.itHub.updateIthub(request, {
                    ...formData,
                    isactive: formData.status_ithub === '1' ? true : false,
                    isarchived: formData.status_ithub === '2' ? true : false,
                    isinactive: formData.status_ithub === '3' ? true : false,
                });
                
                if (editResponse.data.status === 200) {
                    toastr.success('Record updated successfully!')
                    navigate('/manageithub')
                }
                setIsfetching(false);
                
            } catch (error) {
                console.log(error.message)
            }
        } else {
            try {
                const statusMap = {
                    1: true, // isActive
                    2: true, // isArchive
                    3: true, // isApprove
                };
                const statusValue = statusMap[formData.status_ithub];
                const request = { resource: 'api/ithub' };
                setIsfetching(true);
                setShowForm(false)
                const res = await api.itHub.addDetails(request, {
                    ...formData,
                    isactive: formData.status_ithub === '1' ? true : false,
                    isarchived: formData.status_ithub === '2' ? true : false,
                    isinactive: formData.status_ithub === '3' ? true : false,
                });
    
                if (res.data.status === 200) {
                    toastr.success('Success', ' Record added Successfully!');
                    setIsfetching(false);
                    navigate('/manageithub')
                    
                } else {
                    toastr.error('Error', res.data.message);
                }
                
            } catch (error) {
                console.log(error.message);
            }
        }
        reset();
        setIsfetching(false);
        setShowForm(true)
       
    };

    const siteOptions = sites.map((site) => ({
        value: site.country,
        label: site.country
    }));

    useEffect(()=> {
        if((role_id !== 1)&&(role_id !== 6)) {
            navigate('/home');
        }
    }, []);

  return (
    <div className={`${styles.dataTableWrapper} ${styles.formWrapper} mx-auto p-2`}>
    <h4 className="my-4 pagetitle">IT Hub Form</h4>
   {isfetching && <ContainerLoader/>}
   {showForm && <div className='row'>
           <div className='col-12'>
               <div className='walloffame_form w-sm-100 mx-auto'>
               <Form onSubmit={handleSubmit(handleData)} className="d-flex flex-wrap justify-content-between">
          
           

           
           <div className='col-12 col-md-6 p-2'>
               <Form.Group
               className="mb-3"
               controlId="exampleForm.ControlTextarea1"
           >
               <Form.Label>Imageurl<span className='text-danger'>*</span></Form.Label>
               <Form.Control
                   name="imageurl"      
                   type="url"
                   {...register('imageurl', { required: ' Imageurl is required' })}
               />
                {errors.imageurl && <p style={{color:'red'}}>{errors.imageurl.message}</p>}
           </Form.Group>
           </div>
           <div className='col-12 col-md-6 p-2'>
               <Form.Group
               className="mb-3"
               controlId="exampleForm.ControlTextarea1"
           >
               <Form.Label>Redirecturl<span className='text-danger'>*</span></Form.Label>
               <Form.Control
                   name="redirecturl"
                   type="url"
                   {...register('redirecturl', { required: 'Redirecturl is required' })}
               />
                {errors.redirecturl && <p style={{color:'red'}}>{errors.redirecturl.message}</p>}
           </Form.Group>
           </div>
           <div className='col-12 col-md-12 p-2'>
               <Form.Group
               className="mb-3"
               controlId="exampleForm.ControlTextarea1"
           >
               <Form.Label>Description<span className='text-danger'>*</span></Form.Label>
               <Form.Control
                   name="description"
                   as="textarea"
                   rows={3}
                   {...register('description', { required: 'Description is required' })}
               />
                {errors.description && <p style={{color:'red'}}>{errors.description.message}</p>}
           </Form.Group>
           </div>
          
           
           <div className='col-12 col-md-6 p-2'>
               <Form.Group
               className="mb-3"
               controlId="exampleForm.ControlInput1"
           >
               <Form.Label>Publish Date<span className='text-danger'>*</span></Form.Label>
               <Form.Control
                   // onChange={handleChange}
                   type="date"
                   placeholder="Publish Date"
                   name="publishdate"
                   {...register('publishdate', { required: 'Publish Date is required' })}
                   
               />
               {errors.publishdate && <p style={{color:'red'}}>{errors.publishdate.message}</p>}
           </Form.Group>
           </div>
           <div className='col-12 col-md-6 p-2'>
               <Form.Group
               className="mb-3"
               controlId="exampleForm.ControlInput1"
           >
               <Form.Label>Expiry Date<span className='text-danger'>*</span></Form.Label>
               <Form.Control
                   // onChange={handleChange}
                   type="date"
                   placeholder="Expiry Date"
                   name="expirydate"
                   {...register('expirydate', { required: 'Expiry Date is required' })}
                   
               />
               {errors.expirydate && <p style={{color:'red'}}>{errors.expirydate.message}</p>}
           </Form.Group>
           </div>
           <div className='col-12 col-md-6 p-2'>
               <Form.Group>
               <Form.Label>Select Status<span className='text-danger'>*</span></Form.Label>
               <Form.Select
                   name="status_ithub"
                   {...register('status_ithub', { required: 'Status is required' })}
                  
                   className="mb-3"
                   aria-label="Default select example"
                   placeholder="Select Type"
               >
                    <option value="">Select Status</option> 
                   <option value="1">Active</option>
                   <option value="2">Archived</option>
                   <option value="3">Inactive</option>
               </Form.Select>
               {errors.status_ithub && <p style={{color:'red'}}>{errors.status_ithub.message}</p>}
           </Form.Group>
           </div>
           {/* <div className='col-12 col-md-6 p-2'>
           <Form.Group>
               <Form.Label>Select SiteAccess</Form.Label>
               <Form.Select
                   name="siteaccess"
                   {...register('siteaccess', { required: 'siteaccess is required' })}
                  
                   className="mb-3"
                   aria-label="Default select example"
                   placeholder="Select Type"
               >
                   <option value="">Select SiteAccess</option> 
                   {sites.map((opt,index) => {
                        return <option key={index} value={opt.country}>{opt.country}</option>
                   })}
               </Form.Select>
               {errors.siteaccess && <p style={{color:'red'}}>{errors.siteaccess.message}</p>}
           </Form.Group>
           </div> */}

        <div className='col-12 col-md-6 p-2'>
                <Form.Group>
                    <Form.Label>Select SiteAccess<span className='text-danger'>*</span></Form.Label>
                    <Controller
                       name='siteaccessmulti'
                       control={control}
                       defaultValue={[]}
                       rules={{ required: 'siteAccess is required' }}
                        render={({ field }) => (
                            <MultiSelect
                                options={siteOptions}
                                value={field.value}
                                onChange={field.onChange}
                                labelledBy="Select"
                            />
                        )}
                    >
                    </Controller>
                    {errors.siteaccessmulti && <p style={{color:'red'}}>{errors.siteaccessmulti.message}</p>}
                </Form.Group>
                </div>
           <div className='d-flex justify-content-end col-12 p-2'>
                <Button variant="secondary" className='me-2' onClick={()=> navigate('/manageithub')}>
                     Cancel
                </Button>
                <Button type="reset" variant="secondary" className='mx-2' onClick={()=> reset()}>
                    Reset
                </Button>
                <Button type="submit" variant="primary" className='ms-2'>
                    Save
                </Button>
                </div>
       </Form>
               </div>
           </div>
       </div>}
   

</div>
  )
}

export default ItHubForm