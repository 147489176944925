import React, { useState } from 'react'
import './ProcessHub.scss';
import SideBar from './sideBar/SideBar';
import Detail from './details/Detail';

function ProcessHub() {
    const [selectedDept, setSelectedDept] = useState({});

    return (
        <div className="hubContainer">
            <div className="row" style={{marginTop: '-2px'}}>
                <div className="col-md-3 sidebar">
                    <SideBar selectedDept={selectedDept} setSelectedDept={setSelectedDept} />
                </div>
                <div className="col-md-9 bg-light p-3">
                    <Detail selectedDept={selectedDept} />
                </div>
            </div>
        </div>
    )
}

export default ProcessHub