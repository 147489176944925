import React from 'react';
import Navbar from '../navbar/navbar';
import { Container } from 'react-bootstrap';
import Main from '../main/main';
import Footer from '../footer/footer';
import ConsentForm from '../consentForm/ConsentForm';
import '../sidebar/sidebar.scss';
import { useLocation } from 'react-router-dom';
import ProcessHubNavBar from '../processHub/processHubNavbar/ProcessHubNavBar';
import MarketingHubNavBar from '../marketingHub/marketingHubNavbar/MarketingHubNavBar';
import { MarketingHubRouteKeys, ProcessHubRouteKeys } from '../../containers/routes/route-keys';

export default function Layout({ children }) {
    const location = useLocation();
    const isProcessHubRoute = Object.values(ProcessHubRouteKeys).includes(location?.pathname);
    const isMarketingHubRoute = Object.values(MarketingHubRouteKeys).includes(location?.pathname);

    return (
        <>
            <ConsentForm />
            { isProcessHubRoute ? <ProcessHubNavBar /> : isMarketingHubRoute ? <MarketingHubNavBar /> : <Navbar /> }
            <Container fluid>
                <Main>{children}</Main>
            </Container>
            <Footer />
        </>
    );
}
