import ApiService from './service';
import * as http from './http';

export class DocumentDetailService extends ApiService {
    async getDocumentDetails(request) {
        const url = `${this.apiDomain}/${request.resource}`;
        const response = await http
            .get(url, this.store)
            .catch((err) => console.log(err));
        return { data: response.data };
    }

    async addDocumentDetails(request, data) {
        const url = `${this.apiDomain}/${request.resource}`;
        return http.post(url, data, this.store);
    }

    async updateDocumentDetails(request, data) {
        const url = `${this.apiDomain}/${request.resource}`;
        return http.put(url, data, this.store);
    }

    async softDeleteDocumentDetails(request) {
        const url = `${this.apiDomain}/${request.resource}`;
        const data = {};
        return http.patch(url, data, this.store);
    }

    async getDocumentsCategory(request) {
        const url = `${this.apiDomain}/${request.resource}`;
        const response = await http
            .get(url, this.store)
            .catch((err) => console.log(err));
        return { data: response.data };
    }

    async addDocumentsCategory(request, data) {
        const url = `${this.apiDomain}/${request.resource}`;
        return http.post(url, data, this.store);
    }

    async updateDocumentsCategory(request, data) {
        const url = `${this.apiDomain}/${request.resource}`;
        return http.put(url, data, this.store);
    }

    async softDeleteDocumentsCategory(request) {
        const url = `${this.apiDomain}/${request.resource}`;
        const data = {};
        return http.patch(url, data, this.store);
    }
}
