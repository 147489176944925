import React, { useEffect, useState } from 'react'
import api from '../../../api';
import ContainerLoader from '../../loader/container-loader';
import DocumentCards from './DocumentCards';

function DeptDocs({ selectedDept }) {
  const [documents, setDocuments] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const groupedAsArray = (inputArray) => Object.values(
    inputArray.reduce((acc, document) => {
      const { categoryid, documents_category } = document;
      const category = documents_category.category;
      if (!acc[categoryid]) {
        acc[categoryid] = {
          categoryid,
          category,
          documents: []
        };
      }
      acc[categoryid].documents.push(document);

      return acc;
    }, {})
  );

  const fetchDocuments = async (id) => {
    try {
      if(id) {
        setIsFetching(true);
        const request = { resource: `api/documentdetails/${id}` };
        const response = await api.documentDetails.getDocumentDetails(request);
        const filteredData = response?.data?.data?.res?.filter(item => (item?.isdeleted === false));
        setDocuments(groupedAsArray(filteredData))
      } else {
        return null
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsFetching(false);
    }
  }

  useEffect(() => {
    fetchDocuments(selectedDept?.id);
  }, [selectedDept])

  return (
    <div className='p-1'>
      {isFetching ? <ContainerLoader /> :
        <div className="row">
          {(documents?.length > 0 && !isFetching) ? documents.map((item, index) => <DocumentCards documents={item?.documents} category={item?.category} key={index} />) :
            <h5 className='text-center pt-5'>No Documents Found</h5>}
        </div>
      }
    </div>
  )
}

export default DeptDocs