import React from 'react'
import { useEffect,useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { toastr } from 'react-redux-toastr';
import api from '../../api';
import { useForm, Controller } from 'react-hook-form';
import 'bootstrap/dist/css/bootstrap.min.css';
import ContainerLoader from '../loader/container-loader';
import styles from './wallOfFrame.module.css'
import { useLocation, useNavigate } from 'react-router-dom';
import {MultiSelect} from 'react-multi-select-component';
import { useSelector } from 'react-redux';

const WalloffameForm = () => {
    const [isfetching, setIsfetching] = useState(false);
    const [showForm, setShowForm] = useState(true)
    const [userDetails, setUserDetails] = useState([]);
    const [empEmail, setEmployeeEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [refrenceEmail, setRefrenceEmail] = useState('');
    const [refrenceError, setRefrenceError] = useState('');
    const location = useLocation();
    const { state } = useLocation();
    const navigate = useNavigate();

    const intialData = location.state?.intialData || {};
    const data = location.state?.data || {};
    const currentId = location.state?.currentId || {};
    const sites = location.state?.sites || {};
    const clients = location.state?.clients || {};
    const types = location.state?.types || {};
    const isEdit = state ? state.isEdit : false;
    const authState = useSelector((state) => state.authUser);
    const role_id = authState?.profile?.role?.role_id;


    // To check employe details 
    const fetchUserTable = async () => {
        try {
            setIsfetching(true);
            const response = await api.clientsinformation.getDetails({
                resource: `api/zoho/getAllEmployees`,
            });
            const details = response?.data?.details || [];
            setUserDetails(details);
        } catch (error) {
            console.log(error);
        } finally {
            setIsfetching(false);
        }
    };

    useEffect(() => {
        fetchUserTable();
    }, [])

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        control
    } = useForm();

    useEffect(() => {
        if (intialData) {
            Object.keys(intialData).forEach((key) => {
                if (key === 'publishdate') {
                    const formattedPublishDate = intialData.publishdate ? new Date(intialData.publishdate).toISOString().split('T')[0] : null;
                    setValue('publishdate', formattedPublishDate);
                } else if (key === 'expirydate') {
                    const formattedExpirydate = intialData.expirydate ? new Date(intialData.expirydate).toISOString().split('T')[0] : null;
                    setValue('expirydate', formattedExpirydate);
                } else if (key === 'isactive' || key === 'isarchived' || key === 'isapproved') {
                    setValue('status_walloffame', intialData.isactive ? '1' : intialData.isarchived ? '2' : intialData.isapproved ? '3' : '');
                } else {
                    setValue(key, intialData[key]);
                }
            });
            const user = userDetails.find(user => user.emailid.toLowerCase() === intialData?.employeeEmail);
            if(user) {
                const userName = user?.firstname + " " + user?.lastname;
                setEmployeeEmail(userName);
            }
            const refUser = userDetails.find(user => user.emailid.toLowerCase() === intialData?.raisedbyEmail);
            if(refUser) {
                const refUserName = refUser?.firstname + " " + refUser?.lastname
                setRefrenceEmail(refUserName);
            }
        }
    }, [intialData, setValue]);

    const getClientNameByID = (id) => {
        return clients.filter(client => client.client_id == id)[0].client_name;
    }

    const handleData = async (formData) => {
        if (emailError || refrenceError) {
            toastr.error('Invalid Email');
            return
        }
        if (refrenceEmail === empEmail) {
            toastr.error('EmployeeEmail and Referred Email should be Different');
            return
        }

        if (isEdit) {
            try {
                setIsfetching(true);
                setShowForm(false);
                const request = { resource: `api/walloffame/${currentId}` };
                const editResponse = await api.walloffame.updateWallofFame(request, {
                    ...formData,
                    isactive: formData.status_walloffame === '1' ? true : false,
                    isarchived: formData.status_walloffame === '2' ? true : false,
                    isapproved: formData.status_walloffame === '3' ? true : false,
                    clientname: getClientNameByID(formData?.clientid),
                });                
                if (editResponse.data.status === 200) {
                    toastr.success('Record updated successfully!')
                    navigate('/walloffameDetails')
                }
                setIsfetching(false);
                
            } catch (error) {
                console.log(error.message)
            }
        } else {
            try {
                const statusMap = {
                    1: true, // isActive
                    2: true, // isArchive
                    3: true, // isApprove
                };
                const statusValue = statusMap[formData.status_walloffame];
                const request = { resource: 'api/walloffame' };
                setIsfetching(true);
                setShowForm(false)
                const res = await api.walloffame.addDetails(request, {
                    ...formData,
                    isactive: formData.status_walloffame === '1' ? true : false,
                    isarchived: formData.status_walloffame === '2' ? true : false,
                    isapproved: formData.status_walloffame === '3' ? true : false,
                    clientname: getClientNameByID(formData?.clientid),
                });
    
                if (res.data.status === 200) {
                    toastr.success('Success', ' Record added Successfully!');
                    setIsfetching(false);
                    navigate('/walloffameDetails')
                    
                } else {
                    toastr.error('Error', res.data.message);
                }
                
            } catch (error) {
                console.log(error.message);
            }
        }
        reset();
        setIsfetching(false);
        setShowForm(true)
       
    };
    const siteOptions = sites.map((site) => ({
        value: site.country,
        label: site.country
    }));

    const handleEmployeeIdBlur = async (event) => {
        const employeeEmail = event.target.value.toLowerCase();
        const user = userDetails.find(user => user.emailid.toLowerCase() === employeeEmail);
        if (user) {
            let userName = user.firstname + " " + user.lastname
            setEmployeeEmail(userName);
            setEmailError('');
        } else {
            setEmployeeEmail('');
            setEmailError('Employee Email not valid');
            // toastr.error('Error', 'Employee ID not found');
        }
    };

    const handleReferredIdBlur = async (event) => {
        const referredEmail = event.target.value.toLowerCase();
        const user = userDetails.find(user => user.emailid.toLowerCase() === referredEmail);
        if (user) {
            let userName =  user.firstname + " " + user.lastname
            setRefrenceEmail(userName);
            setRefrenceError('');
        } else {
            setRefrenceEmail('');
            setRefrenceError('Referred ID not valid');
            // toastr.error('Error', 'Referred ID not found');
        }
    };

    useEffect(() => {
        const user = userDetails.find(user => user.emailid.toLowerCase() === intialData?.employeeEmail);
            if(user) {
                const userName = user?.firstname + " " + user?.lastname;
                setEmployeeEmail(userName);
            }
            const refUser = userDetails.find(user => user.emailid.toLowerCase() === intialData?.raisedbyEmail);
            if(refUser) {
                const refUserName = refUser?.firstname + " " + refUser?.lastname
                setRefrenceEmail(refUserName);
            }
    }, [userDetails])
    
    useEffect(()=> {
        if((role_id !== 1)&&(role_id !== 6)) {
            navigate('/home');
        }
    }, []);

  return (
    <div className={`${styles.dataTableWrapper} ${styles.formWrapper} mx-auto p-2`}>
         <h4 className="my-4 pagetitle">Wall Of Fame Form</h4>
        {isfetching && <ContainerLoader/>}
        {showForm && userDetails.length > 0 && <div className='row'>
                <div className='col-12'>
                    <div className='walloffame_form w-sm-100 mx-auto'>
                    <Form onSubmit={handleSubmit(handleData)} className="d-flex flex-wrap justify-content-between">
                <div className='col-12 col-md-6 p-2'>
                    <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                >
                    <Form.Label>Employee Email<span className='text-danger'>*</span></Form.Label>
                    <Form.Control
                        // onChange={handleChange}
                        type="email"
                        placeholder="Employee-Email"
                        name="employeeEmail"
                        {...register('employeeEmail', { required: 'Employee Email is required' })}
                        onBlur={handleEmployeeIdBlur}

                    />
                    {errors.employeeEmail && <p style={{color:'red'}}>{errors.employeeEmail.message}</p>}
                    {empEmail && <p className={styles.emailToast}>{empEmail}</p>}
                    {emailError && <p style={{ color: 'red' }}>{emailError}</p>}
                </Form.Group>
                </div>
                <div className='col-12 col-md-6 p-2'>
                    <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                >
                    <Form.Label>Referred By Email<span className='text-danger'>*</span></Form.Label>
                    <Form.Control
                        // onChange={handleChange}
                        type="email"
                        placeholder="Referred-Email"
                        name="raisedbyEmail"
                        {...register('raisedbyEmail', { required: 'Reference Email Required' })}
                        onBlur={handleReferredIdBlur}

                    />
                    {errors.raisedbyEmail && <p style={{color:'red'}}>{errors.raisedbyEmail.message}</p>}
                    {refrenceEmail && <p className={styles.emailToast}>{refrenceEmail}</p>}
                    {refrenceError && <p style={{ color: 'red' }}>{refrenceError}</p>}
                </Form.Group>
                </div>
                <div className='col-12 col-md-6 p-2'>
                <Form.Group>
                    <Form.Label>Select Client<span className='text-danger'>*</span></Form.Label>
                    <Form.Select
                        name="clientid"
                        {...register('clientid', { required: 'Client is required' })}
                       
                        className="mb-0"
                        aria-label="Default select example"
                        placeholder="Select Client"
                    >
                        <option value="">Select Client</option> 
                        { clients?.map((client, index)=><option key={index} value={client.client_id}>{client.client_name}</option>) }
                    </Form.Select>
                    {errors.clientid && <p style={{color:'red'}}>{errors.clientid.message}</p>}
                </Form.Group>
                </div>
                <div className='col-12 col-md-6 p-2'>
                <Form.Group>
                    <Form.Label>Select Wall Of Fame Type<span className='text-danger'>*</span></Form.Label>
                    <Form.Select
                        name="typeid"
                        {...register('typeid', { required: 'Type is required' })}
                       
                        className="mb-0"
                        aria-label="Default select example"
                        placeholder="Select Type"
                    >
                        <option value="">Select Type</option> 
                        {types.map((opt) => {
                             return <option key={opt.id} value={opt.id}>{opt.walloffametype}</option>
                        })}
                    </Form.Select>
                    {errors.typeid && <p style={{color:'red'}}>{errors.typeid.message}</p>}
                </Form.Group>
                </div>

                <div className='col-12 col-md-6 p-2'>
                    <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                >
                    <Form.Label>Description<span className='text-danger'>*</span></Form.Label>
                    <Form.Control
                        name="clientmessage"
                       
                        as="textarea"
                        rows={3}
                        {...register('clientmessage', { required: 'Description is required' })}
                    />
                     {errors.clientmessage && <p style={{color:'red'}}>{errors.clientmessage.message}</p>}
                </Form.Group>
                </div>
                <div className='col-12 col-md-6 p-2'>
                    <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                >
                    <Form.Label>Client Appreciation<span className='text-danger'>*</span></Form.Label>
                    <Form.Control
                        name="clientappreciation"
                       
                        as="textarea"
                        rows={3}
                        {...register('clientappreciation', { required: 'Client Appreciation is required' })}
                    />
                     {errors.clientappreciation && <p style={{color:'red'}}>{errors.clientappreciation.message}</p>}
                </Form.Group>
                </div>
                <div className='col-12 col-md-12 p-2'>
                    <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                    >
                        <Form.Label>Image URL</Form.Label>
                        <Form.Control
                            name="imageurl"
                            type="url"
                            {...register('imageurl')}
                        />
                        {errors.imageurl && <p style={{ color: 'red' }}>{errors.imageurl.message}</p>}
                    </Form.Group>
                </div>
                <div className='col-12 col-md-6 p-2'>
                    <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                >
                    <Form.Label>Publish Date<span className='text-danger'>*</span></Form.Label>
                    <Form.Control
                        // onChange={handleChange}
                        type="date"
                        placeholder="Publish Date"
                        name="publishdate"
                        {...register('publishdate', { required: 'Publish Date is required' })}
                        
                    />
                    {errors.publishdate && <p style={{color:'red'}}>{errors.publishdate.message}</p>}
                </Form.Group>
                </div>
                <div className='col-12 col-md-6 p-2'>
                    <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                >
                    <Form.Label>Expiry Date<span className='text-danger'>*</span></Form.Label>
                    <Form.Control
                        // onChange={handleChange}
                        type="date"
                        placeholder="Expiry Date"
                        name="expirydate"
                        {...register('expirydate', { required: 'Expiry Date is required' })}
                        
                    />
                    {errors.expirydate && <p style={{color:'red'}}>{errors.expirydate.message}</p>}
                </Form.Group>
                </div>
                <div className='col-12 col-md-6 p-2'>
                    <Form.Group>
                    <Form.Label>Select Status<span className='text-danger'>*</span></Form.Label>
                    <Form.Select
                        name="status_walloffame"
                        {...register('status_walloffame', { required: 'Status is required' })}
                       
                        className="mb-0"
                        aria-label="Default select example"
                        placeholder="Select Type"
                    >
                         <option value="">Select Status</option> 
                        <option value="1">Active</option>
                        <option value="2">Archived</option>
                        <option value="3">Approved</option>
                    </Form.Select>
                    {errors.status_walloffame && <p style={{color:'red'}}>{errors.status_walloffame.message}</p>}
                </Form.Group>
                </div>
                {/* <div className='col-12 col-md-6 p-2'>
                <Form.Group>
                    <Form.Label>Select SiteAccess</Form.Label>
                    <Form.Select
                        name="siteaccess"
                        {...register('siteaccess', { required: 'siteAccess is required' })}
                       
                        className="mb-3"
                        aria-label="Default select example"
                        placeholder="Select Type"
                    >
                        <option value="">Select SiteAccess</option> 
                        {sites.map((opt,index) => {
                             return <option key={index} value={opt.country}>{opt.country}</option>
                        })}
                    </Form.Select>
                    {errors.siteaccess && <p style={{color:'red'}}>{errors.siteaccess.message}</p>}
                </Form.Group>
                </div> */}
                <div className='col-12 col-md-6 p-2'>
                <Form.Group>
                    <Form.Label>Select SiteAccess<span className='text-danger'>*</span></Form.Label>
                    <Controller
                       name='siteaccessmulti'
                       control={control}
                       defaultValue={[]}
                       rules={{ required: 'SiteAccess is required' }}
                        render={({ field }) => (
                            <MultiSelect
                                options={siteOptions}
                                value={field.value}
                                onChange={field.onChange}
                                labelledBy="Select"
                            />
                        )}
                    >
                    </Controller>
                    {errors.siteaccessmulti && <p style={{color:'red'}}>{errors.siteaccessmulti.message}</p>}
                </Form.Group>
                </div>
                <div className='d-flex justify-content-end col-12 p-2'>
                <Button variant="secondary" className='me-2' onClick={()=> navigate('/walloffameDetails')}>
                     Cancel
                </Button>
                <Button type="reset" variant="secondary" className='mx-2' onClick={()=> reset()}>
                    Reset
                </Button>
                <Button type="submit" variant="primary" className='ms-2'>
                    Save
                </Button>
                </div>
            </Form>
                    </div>
                </div>
            </div>}
        

    </div>
  )
}

export default WalloffameForm