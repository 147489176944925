import api from '../../../api';
import About from '../about/About';
import DeptProfile from '../profile/DeptProfile';
import DeptDocs from '../documents/DeptDocs';
import React, { useEffect, useState } from 'react';
import ContainerLoader from '../../loader/container-loader';

function Detail({ selectedDept }) {
    const [departmentDetails, setDepartmentDetails] = useState(null);
    const [isFetching, setIsFetching] = useState(false);

    const fetchDepartmentDetails = async (id) => {
        try {
            setIsFetching(true);
            const response = await api.clientsinformation.getDetails({
                resource: `api/departments/${id}`,
            });
            setDepartmentDetails(response?.data?.data?.res);
        } catch (error) {
            setDepartmentDetails(null);
            console.error(error.message);
        } finally {
            setIsFetching(false);
        }
    };

    useEffect(() => {
        if (selectedDept?.id) {
            fetchDepartmentDetails(selectedDept.id);
        }
    }, [selectedDept]);

    if (isFetching) {
        return <ContainerLoader />;
    }

    return departmentDetails ? (
        <div className="px-3" style={{ minHeight: '900px' }}>
            <h4 className="pagetitle">{departmentDetails.department}</h4>
            <div className="row">
                <div className="col-6">
                    <div className="p-2 card border-0">
                        <DeptProfile selectedDept={departmentDetails} />
                    </div>
                </div>
                <div className="col-6">
                    <div className="p-2 card border-0">
                        <About selectedDept={departmentDetails} />
                    </div>
                </div>
            </div>
            <div>
                <DeptDocs selectedDept={departmentDetails} />
            </div>
        </div>
    ) : (
        <div className="px-3" style={{ minHeight: '900px' }}>No data available!</div>
    );
}

export default Detail;
