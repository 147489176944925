import React, { useState } from 'react';
import './Contact.scss';
import { SlArrowRightCircle } from "react-icons/sl";
import { SlArrowLeftCircle } from "react-icons/sl";

function ContactCard({ baseData }) {
    const [contacts, setContacts] = useState(baseData);
    const [expanded, setExpanded] = useState(false);

    const expandChildren = (subDepartments) => {
        setContacts([baseData, ...subDepartments])
        setExpanded(true);
    }
    const collapseChildren = () => {
        setContacts(baseData);
        setExpanded(false);
    }

    return (
        <>
            {expanded ? <React.Fragment>{
                contacts?.map((item, index) =>
                    <div key={index} className='contactCard m-4 expandedcard' style={{ position: 'relative' }}>
                        <div style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer' }}>
                            {(expanded && item?.subDepartments?.length > 0) && <SlArrowLeftCircle size={30} onClick={() => collapseChildren()} title='Collapse' />}
                        </div>
                        <div className='teamName'>{item.department}</div>
                        {item?.email && <div className='teamMail' title={item?.email}>{item?.email}</div>}
                        <div className='teamSpoc'>{item.spoc}</div>
                    </div>
                )}</React.Fragment> :
                <React.Fragment><div className='contactCard m-4' style={{ position: 'relative' }}>
                    {(contacts?.subDepartments?.length > 0) && <div style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer' }}>
                        {baseData?.subDepartments?.length > 0 && <SlArrowRightCircle size={30} onClick={() => expandChildren(contacts?.subDepartments)} title='Expand' />}
                    </div>}
                    <div className='teamName'>{contacts.department}</div>
                    {contacts?.email && <div className='teamMail' title={contacts?.email}>{contacts?.email}</div>}
                    <div className='teamSpoc'>{contacts.spoc}</div>
                </div></React.Fragment>
            }
        </>
    )
}

export default ContactCard
