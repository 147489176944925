import api from '../../../api';
import { React, useState, useEffect } from 'react';
import { FiPhone } from 'react-icons/fi';
import { TfiEmail } from 'react-icons/tfi';
import userIconPlaceholder from '../../../assets/logo/usericon.png';

function DeptProfile({ selectedDept }) {
    const [employeeDetails, setEmployeeDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const fetchEmployeeDetails = async (email) => {
        try {
            setIsLoading(true);
            const response = await api.clientsinformation.getDetails({
                resource: `api/zoho/getAllEmployees/${email}`,
            });
            const details = response?.data?.details?.[0];
            setEmployeeDetails(details);
        } catch (error) {
            console.error('Error fetching employee details:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (selectedDept?.spocemail) {
            fetchEmployeeDetails(selectedDept?.spocemail);
        }
    }, [selectedDept]);

    return (
        <div className="d-flex p-2 h-100 rounded">
            <div className="flex-shrink-0 d-flex justify-content-center align-items-center rounded border p-2">
                <div
                    className="bg-secondary rounded-circle overflow-hidden border"
                    style={{
                        width: '100px',
                        height: '100px',
                    }}
                >
                    {isLoading ? (
                        <div
                            className="placeholder-glow"
                            style={{
                                width: '100px',
                                height: '100px',
                                borderRadius: '50%',
                                backgroundColor: '#e0e0e0',
                            }}
                        ></div>
                    ) : (
                        <img
                            src={userIconPlaceholder}
                            alt="Profile Avatar"
                            className="img-fluid"
                            style={{ objectFit: 'cover' }}
                        />
                    )}
                </div>
            </div>

            <div className="ms-4 d-flex flex-column justify-content-between w-100">
                {isLoading ? (
                    <div className="w-100">
                        <div className="placeholder-glow">
                            <div className="placeholder col-8 mb-2"></div>
                        </div>
                        <div className="placeholder-glow">
                            <div className="placeholder col-6 mb-2"></div>
                        </div>
                        <div className="placeholder-glow">
                            <div className="placeholder col-4"></div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <h6 className="text-primary font-bold fs-6 mb-1">
                            {selectedDept?.spoc}
                        </h6>
                        <p
                            className="mb-1 font-bold"
                            style={{ fontSize: '13px' }}
                        >
                            {employeeDetails?.title}
                        </p>
                        <p
                            className="mb-3 font-bold"
                            style={{ fontSize: '12px' }}
                        >
                            {selectedDept?.department}
                        </p>
                    </div>
                )}

                {isLoading ? (
                    <div className="d-flex gap-3">
                        <div className="placeholder-glow">
                            <div className="placeholder col-3"></div>
                        </div>
                        <div className="placeholder-glow">
                            <div className="placeholder col-3"></div>
                        </div>
                    </div>
                ) : (
                    <div className="d-flex gap-3">
                        <a
                            className="text-primary d-flex align-items-center"
                            href={`mailto:${selectedDept?.spocemail}`}
                            style={{
                                fontSize: '1.5rem',
                                textDecoration: 'none',
                                color: '#2f48ab',
                            }}
                        >
                            <TfiEmail />
                        </a>
                        <a
                            className="d-flex align-items-center"
                            href={`tel:${employeeDetails?.work_phone}`}
                            style={{
                                fontSize: '1.5rem',
                                textDecoration: 'none',
                                color: '#2f48ab',
                            }}
                        >
                            <FiPhone />
                        </a>
                    </div>
                )}
            </div>
        </div>
    );
}

export default DeptProfile;
