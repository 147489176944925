import React, { useState } from 'react';
import { IoGridOutline } from "react-icons/io5";
import { FaListUl } from "react-icons/fa";
import { capitalFirstChar } from '../../../utils/capitalFirstChar';
import { getDocumentTypeFromUrl, getIconFromUrl } from '../../../utils/getDocumentTypeFromUrl';
import './Documents.scss';

function DocumentCards({ documents, category }) {
    const [isGridView, setIsGridView] = useState(true);
  
    return (
        <div className='col-12 col-md-6'>
            <div className="border light shadow rounded mt-3 bg-white p-1">
                <div className="d-flex justify-content-between align-items-center mt-2 mx-2">
                    <h5 className='cardTitle'>{capitalFirstChar(category)}</h5>
                    <div>
                        <IoGridOutline style={{ cursor: 'pointer' }} className="me-1" color={`${isGridView ? '#0d6efd' : ''}`} onClick={() => setIsGridView(true)} />
                        <FaListUl style={{ cursor: 'pointer' }} className="ms-1" color={`${!isGridView ? '#0d6efd' : ''}`} onClick={() => setIsGridView(false)} />
                    </div>
                </div>
                <div className={isGridView ? "row" : "listViewContainer"} style={{maxHeight:'300px', overflow:'auto'}}>
                    {documents.map((item, index) => (
                        <div
                            key={index}
                            className={`docView mb-1 ${isGridView ? `col-6 col-md-6 ${documents.length < 3 ? 'col-lg-6' : 'col-lg-4'}` : ''}`} 
                            onClick={()=>{window.open(item?.url)}}
                        >
                            <div style={{ cursor: 'pointer' }} className={`d-flex align-items-center px-1 mx-3 ${isGridView ? 'py-3 border border-medium rounded m-1 text-left p-1' : 'listView'}`}
                            >
                                <div>
                                    {getIconFromUrl(item?.url)}
                                </div>
                                <h6 className="mb-0" title={item?.name}>{`${item?.name}.${getDocumentTypeFromUrl(item?.url)}`}</h6>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default DocumentCards