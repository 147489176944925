export const RouteKeys = {
    Login: '/auth/login',
    Home: '/',
    NotFound: '/page-not-found',
    Homeroute: 'home',
    Auth: '/auth',
    AzureLogin: '/auth/aad-redirect',
    AddUser: '/users',
    CreateUser: '/addUser',
    EditUser: '/edituser/:id',
    About: '/about',
    EmployeeGram: '/employeeGram',
    FindYourAssociates: '/findYourAssociates',
    AssociateDetails: '/associateDetails',
    wallOfFame: '/walloffameDetails',
    whatsnew: '/buddyreferrals',
    WhatsNewForm: '/buddyreferralsform',
    PoliciesAndBenefits: '/policiesAndBenefits',
    AddPolicyForm: '/addPolicyForm',
    ManagePolicy: '/managePolicy',
    ClientSummary: '/clientSummary',
    wallofFameForm: '/walloffameForm',
    Departments: '/departments',
    LearningLounge: '/learningLounge',
    ManageInsights: '/manageinsights',
    InsightsForm: '/insightsForm',
    ManageItHub: '/manageithub',
    ItHubForm: '/itHubForm',
    ManageFlashNews: '/manageflashnews',
    FlashNewsForm: '/flashNewsForm',
    PrivacyPolicy: '/privacypolicy',
    TermsAndConditions: '/termsandconditions',
    OrganizationChart: '/organizationChart',
    MangageLearningLounge: '/managelearninglounge',
    LearningLoungeTable: '/learningloungetable',
    LearningLoungeForm: '/learningloungeform',
    ManageClientDetails: '/manageclientdetails',
    ClientDetailsForm: '/clientdetailsForm',
    ManageDepartments: '/manageDepartments',
    DepartmentsForm: '/departmentsForm',
    Reports: '/reports',
    Survey: '/survey',
    ManageSurvey: '/managesurvey',
    SurveyMasterForm: '/surveyMasterForm',
    SurveyQuestionsForm: '/surveyQuestionsForm',
    TalentTransformationalHub: '/talentTransformationalHub',
};

export const ProcessHubRouteKeys = {
    ProcessHub: '/processHub',
    ManageDocuments: '/manageDocuments',
    DocumentsForm: '/documentsForm',
}

export const MarketingHubRouteKeys = {
    MarketingHub: '/marketingHub',
    Solutions: '/solutions',
    ManageSolutions: '/manageSolutions',
    SolutionsForm: '/solutionsForm',
    ManageMarketingBrochures: '/manageMarketingBrochures',
    MarketingBrochuresForm: '/marketingBrochuresForm',
    ManageMarketingEvents: '/manageMarketingEvents',
    MarketingEventsForm: '/marketingEventsForm',
    ManageRecommendedVideos: '/manageRecommendedVideos',
    RecommendedVideosForm: '/recommendedVideosForm',
    ManageMarketingVideos: '/manageMarketingVideos',
    MarketingVideosForm: '/marketingVideosForm',
    ManageIndustryNews: '/manageindustrynews',
    IndustryNewsForm: '/industrynewsform',
    ManageThoughtLeadership: '/managethoughtleadership',
    ThoughtLeadershipForm: '/thoughtleadershipform',
    ManagePodcasts: '/managepodcasts',
    PodcastsForm: '/podcastsform',
    ManageMarketingAwards: '/managemarketingawards',
    MarketingAwardsForm: '/marketingawardsform',
    ManageAuthors: '/manageAuthors',
    AuthorsForm: '/authorsForm'
}

