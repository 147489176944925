import React from 'react';
import { useState } from 'react';
import {
    Navbar,
    Container,
    Nav,
    NavDropdown,
} from 'react-bootstrap';
import { useNavigate, NavLink } from 'react-router-dom';
import brandLogo from '../../../assets/logo/AgilisiumFullWhiteLogo.png';
import MarketingHubLogo from '../../../assets/logo/MarketingHubWhite.png';
import { useSelector } from 'react-redux';

function MarketingHubNavBar() {
    const [show, setShow] = useState(false);
    const [activeDropdown, setActiveDropdown] = useState(null);
    const navigate = useNavigate();
    const authState = useSelector((state) => state.authUser);
    const role_id = authState?.profile?.role?.role_id;

    const handleClose = (dropdown) => {
        setShow(false);
        setActiveDropdown(dropdown);
    };

    return (
        <>
            <Navbar
                expand="lg"
                className="bg-body-tertiary p-0"
                expanded={show}
                style={{ position: 'fixed', width: '100%', zIndex: '999', padding: '100px !important' }}
            >
                <Container
                    fluid
                    style={{
                        background: '#3B6FD0',
                    }}
                >
                    <Navbar.Brand
                        className="brand"
                        onClick={() => { navigate('/marketingHub'); handleClose('home') }}
                    >
                        <img
                            src={MarketingHubLogo}
                            alt="brand"
                            style={{
                                top: '19px',
                                left: '48px',
                                height: '58px',
                                // background: 'white 0% 0% no-repeat padding-box',
                                opacity: '1',
                                cursor: 'pointer',
                            }}
                        />
                    </Navbar.Brand>

                    <Navbar.Toggle
                        aria-controls="phNavbarScroll"
                        onClick={() => setShow(!show)}
                    />
                    <Navbar.Collapse id="phNavbarScroll">
                        <Nav
                            className="me-auto my-2 my-lg-0"
                            // style={{ maxHeight: '100px' }}
                            navbarScroll
                        >
                            <Nav.Link
                                as={NavLink}
                                to="/marketingHub"
                                className={`px-4 navHeader ${activeDropdown === 'home' ? 'active' : ''}`}
                                onClick={() => handleClose('home')}
                            >
                                Home
                            </Nav.Link>
                            <Nav.Link
                                as={NavLink}
                                to="/solutions"
                                className={`px-4 navHeader ${activeDropdown === 'solutions' ? 'active' : ''}`}
                                onClick={() => handleClose('solutions')}
                            >
                                Solutions
                            </Nav.Link>
                            <NavDropdown
                                title="Our Hubs"
                                id="navbarScrollingDropdown"
                                className={`px-4 navHeader ${activeDropdown === 'ourhubs' ? 'active' : ''}`}
                            >
                                <NavDropdown.Item
                                    as={NavLink}
                                    to="/home"
                                    className="bg-transparent menulink"
                                    onClick={() => handleClose('ourhubs')}
                                >
                                    People Hub
                                </NavDropdown.Item>
                                <NavDropdown.Divider className='divider'/>
                                <NavDropdown.Item
                                    as={NavLink}
                                    to="/processHub"
                                    className="bg-transparent menulink"
                                    onClick={() => handleClose('ourhubs')}
                                >
                                    Process Hub
                                </NavDropdown.Item>
                                <NavDropdown.Divider className='divider'/>
                                <NavDropdown.Item
                                    as={NavLink}
                                    to="/talentTransformationalHub"
                                    className="bg-transparent menulink"
                                    onClick={() => handleClose('ourhubs')}
                                >
                                    Talent Transformational Hub
                                </NavDropdown.Item>                                
                            </NavDropdown>
                            {(role_id === 6 || role_id === 1) && <NavDropdown
                                title="Admin"
                                id="navbarScrollingDropdown"
                                className={`px-4 navHeader ${activeDropdown === 'admin' ? 'active' : ''}`}
                            >
                                 <NavDropdown.Item
                                    as={NavLink}
                                    to="/manageSolutions"
                                    className="bg-transparent menulink"
                                    onClick={() => handleClose('admin')}
                                >
                                    Manage Solutions
                                </NavDropdown.Item>
                                <NavDropdown.Divider className='divider'/>
                                <NavDropdown.Item
                                    as={NavLink}
                                    to="/manageRecommendedVideos"
                                    className="bg-transparent menulink"
                                    onClick={() => handleClose('admin')}
                                >
                                    Manage Recommended Videos
                                </NavDropdown.Item>
                                <NavDropdown.Divider className='divider'/>
                                <NavDropdown.Item
                                    as={NavLink}
                                    to="/manageAuthors"
                                    className="bg-transparent menulink"
                                    onClick={() => handleClose('admin')}
                                >
                                    Manage Authors
                                </NavDropdown.Item>
                                <NavDropdown.Divider className='divider'/>
                                <NavDropdown.Item
                                    as={NavLink}
                                    to="/manageMarketingBrochures"
                                    className="bg-transparent menulink"
                                    onClick={() => handleClose('admin')}
                                >
                                    Manage Marketing Brochures
                                </NavDropdown.Item>
                                <NavDropdown.Divider className='divider'/>
                                <NavDropdown.Item
                                    as={NavLink}
                                    to="/manageMarketingEvents"
                                    className="bg-transparent menulink"
                                    onClick={() => handleClose('admin')}
                                >
                                    Manage Marketing Events
                                </NavDropdown.Item>
                                <NavDropdown.Divider className='divider'/>
                                <NavDropdown.Item
                                    as={NavLink}
                                    to="/manageindustrynews"
                                    className="bg-transparent menulink"
                                    onClick={() => handleClose('admin')}
                                >
                                    Manage Industry News
                                </NavDropdown.Item>
                                <NavDropdown.Divider className='divider'/>
                                <NavDropdown.Item
                                    as={NavLink}
                                    to="/managethoughtleadership"
                                    className="bg-transparent menulink"
                                    onClick={() => handleClose('admin')}
                                >
                                    Manage Thought Leadership
                                </NavDropdown.Item>
                                <NavDropdown.Divider className='divider'/>
                                <NavDropdown.Item
                                    as={NavLink}
                                    to="/manageMarketingVideos"
                                    className="bg-transparent menulink"
                                    onClick={() => handleClose('admin')}
                                >
                                    Manage Videos
                                </NavDropdown.Item>
                                <NavDropdown.Divider className='divider'/>
                                <NavDropdown.Item
                                    as={NavLink}
                                    to="/managepodcasts"
                                    className="bg-transparent menulink"
                                    onClick={() => handleClose('admin')}
                                >
                                    Manage Podcasts
                                </NavDropdown.Item>
                                <NavDropdown.Divider className='divider'/>
                                <NavDropdown.Item
                                    as={NavLink}
                                    to="/managemarketingawards"
                                    className="bg-transparent menulink"
                                    onClick={() => handleClose('admin')}
                                >
                                    Manage Marketing Awards
                                </NavDropdown.Item>
                            </NavDropdown>}
                        </Nav>
                    </Navbar.Collapse>



                    <Navbar.Brand
                        className="brand rightbrand"
                        onClick={() => navigate('/')}
                    >
                        <img
                            src={brandLogo}
                            alt="brand"
                            style={{
                                top: '19px',
                                left: '48px',
                                width: '189px',
                                height: '58px',
                                // background: 'white 0% 0% no-repeat padding-box',
                                opacity: '1',
                                cursor: 'pointer',
                            }}
                        />
                    </Navbar.Brand>
                </Container>
            </Navbar>
        </>
    );
}

export default MarketingHubNavBar